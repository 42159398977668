
import { SearchOutlined } from '@ant-design/icons-vue'
import { CircleMinus } from '@vicons/tabler'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  Ref,
  ref,
  watch
} from 'vue'

import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { EMIT_EVENTS } from '@/constants/emits'
import { tableScrollY } from '@/libs/utils'
import router from '@/router'
import { RowData } from '@/types'

import { BNode, BState } from './libs/common'
import StateSimple from './StateSimple.vue'

export const EVENTS = {
  ON_REMOVE_NODE: 'onRemoveNode'
}

const COLUMNS = {
  NODE_INDEX: {
    TITLE: 'No',
    KEY: 'nodeIndex',
    DATA_INDEX: 'nodeIndex'
  },
  NODE_KEY: {
    TITLE: 'Key',
    DATA_INDEX: 'nodeKey',
    KEY: 'nodeKey',
    SLOTS: {
      CUSTOM_RENDER: 'nodeKey',
      FILTER_DROPDOWN: 'filterDropdown',
      FILTER_ICON: 'filterIcon'
    }
  },
  NODE_NAME: {
    TITLE: 'Name',
    DATA_INDEX: 'nodeName',
    KEY: 'nodeName',
    SLOTS: {
      CUSTOM_RENDER: 'nodeName',
      FILTER_DROPDOWN: 'filterDropdown',
      FILTER_ICON: 'filterIcon'
    }
  },
  NODE_TYPE: {
    TITLE: 'Node Type',
    DATA_INDEX: 'nodeType',
    KEY: 'nodeType',
    SLOTS: {
      CUSTOM_RENDER: 'nodeType'
    }
  },
  STATES: {
    TITLE: 'States',
    DATA_INDEX: 'states',
    KEY: 'states',
    SLOTS: {
      TITLE: 'statesTitle',
      CUSTOM_RENDER: 'states'
    }
  },
  ACTIONS: {
    TITLE: '',
    DATA_INDEX: 'actions',
    KEY: 'actions',
    SLOTS: {
      CUSTOM_RENDER: 'actions'
    }
  }
}

export default defineComponent({
  components: {
    CircleMinus,
    StateSimple,
    SearchOutlined
  },
  props: {
    bNodes: { type: Object, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const { ACTIONS, NODE_INDEX, NODE_KEY, NODE_NAME, NODE_TYPE, STATES } = COLUMNS

    // const store = useStore()
    const routerParams = router.currentRoute.value.params
    let { workspaceId } = routerParams

    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const searchText = ref('')
    const searchedColumn = ref('')

    const tableSearchInput = ref()
    const scrollY: Ref<null | number> = ref(null)
    const nodeTableWrapper = ref(null)

    const data = computed(() => {
      return props.bNodes.map((bNode: BNode, idx: number) => {
        const data: RowData = {}
        data.key = idx
        data[NODE_INDEX.DATA_INDEX] = idx + 1
        data[NODE_KEY.DATA_INDEX] = bNode.key
        data[NODE_NAME.DATA_INDEX] = bNode.name
        data[NODE_TYPE.DATA_INDEX] = bNode.type
        data[STATES.DATA_INDEX] = bNode.states
        data[ACTIONS.DATA_INDEX] = bNode.key
        data.bNode = bNode
        return data
      })
    })

    const columns = computed(() => {
      return [
        {
          key: NODE_INDEX.KEY,
          dataIndex: NODE_INDEX.DATA_INDEX,
          title: NODE_INDEX.TITLE,
          fixed: 'left',
          width: 40,
          align: 'center'
        },
        {
          key: NODE_KEY.KEY,
          dataIndex: NODE_KEY.DATA_INDEX,
          title: NODE_KEY.TITLE,
          fixed: 'left',
          width: 120,
          slots: {
            customRender: NODE_KEY.SLOTS.CUSTOM_RENDER,
            filterDropdown: NODE_KEY.SLOTS.FILTER_DROPDOWN,
            filterIcon: NODE_KEY.SLOTS.FILTER_ICON
          },
          onFilter: (value: any, record: RowData) =>
            record.bNode.key.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
              setTimeout(() => {
                tableSearchInput.value.focus()
              }, 0)
            }
          }
        },
        {
          key: NODE_NAME.KEY,
          dataIndex: NODE_NAME.DATA_INDEX,
          title: NODE_NAME.TITLE,
          width: 150,
          slots: {
            customRender: NODE_NAME.SLOTS.CUSTOM_RENDER,
            filterDropdown: NODE_NAME.SLOTS.FILTER_DROPDOWN,
            filterIcon: NODE_NAME.SLOTS.FILTER_ICON
          },
          onFilter: (value: any, record: RowData) =>
            record.bNode.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
              setTimeout(() => {
                tableSearchInput.value.focus()
              }, 0)
            }
          }
        },
        {
          key: NODE_TYPE.KEY,
          dataIndex: NODE_TYPE.DATA_INDEX,
          title: NODE_TYPE.TITLE,
          width: 100,
          align: 'center'
        },
        {
          key: STATES.KEY,
          dataIndex: STATES.DATA_INDEX,
          align: 'left',
          slots: {
            title: STATES.SLOTS.TITLE,
            customRender: STATES.SLOTS.CUSTOM_RENDER,
            filterDropdown: NODE_NAME.SLOTS.FILTER_DROPDOWN,
            filterIcon: NODE_NAME.SLOTS.FILTER_ICON
          },
          onFilter: (value: any, record: RowData) =>
            record.bNode.states
              .map((state: BState) => state.name.toLowerCase())
              .join(' ')
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
              setTimeout(() => {
                tableSearchInput.value.focus()
              }, 0)
            }
          }
        },
        {
          key: ACTIONS.KEY,
          dataIndex: ACTIONS.DATA_INDEX,
          title: ACTIONS.TITLE,
          width: 100,
          align: 'left',
          slots: {
            customRender: ACTIONS.SLOTS.CUSTOM_RENDER
          }
        }
      ]
    })

    /**
     * Handle table search
     **/
    const handleSearch = (selectedKeys: Array<any>, confirm: any, dataIndex: string) => {
      confirm()
      searchText.value = selectedKeys[0]
      searchedColumn.value = dataIndex
    }

    /**
     * Handle reset table search
     **/
    const handleReset = (clearFilters: any) => {
      clearFilters()
      searchText.value = ''
    }

    /**
     * Get table scroll y value
     **/
    const onResize = () => {
      const y = tableScrollY(nodeTableWrapper.value, 30)
      if (scrollY.value !== y) {
        scrollY.value = y
      }
    }

    const removeNode = (key: string) => {
      emit(EVENTS.ON_REMOVE_NODE, { key })
    }

    onBeforeMount(() => {
      window.addEventListener('resize', onResize)
    })

    watch(data, () => {
      onResize()
    })

    onMounted(() => {
      onResize()
    })

    onUpdated(() => {
      onResize()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      EMIT_EVENTS,
      COLUMNS,
      API_DEFAULT_PAGEABLE_PARAMS,
      data,
      columns,
      scrollY,
      nodeTableWrapper,
      searchText,
      tableSearchInput,
      searchedColumn,
      handleSearch,
      handleReset,
      removeNode
    }
  }
})
