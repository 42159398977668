import { updateNetwork } from '@/services/api/network'
import { NetworkSchema, NodeSchema } from '@/types'

export const saveNodes = async (network: NetworkSchema, nodes: NodeSchema[]): Promise<any> => {
  network.nodes = nodes
  await updateNetwork({ id: network.id, payload: network })
}

export const saveNetwork = async (network: NetworkSchema): Promise<any> => {
  await updateNetwork({ id: network.id, payload: network })
}
