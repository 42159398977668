<template>
  <div class="sz-builder-state">
    <a-tag :color="color">
      {{ state.name }}
    </a-tag>
  </div>
</template>

<script lang="ts">
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import { computed, defineComponent, PropType } from 'vue'

import { StatePolarity } from '@/libs/bayes/enums/StatePolarity'
import { STATE_THEME } from '@/libs/theme'

import { BState } from './libs/common'

export default defineComponent({
  props: {
    state: { type: Object as PropType<BState>, required: true },
    index: { type: Number, required: true }
  },
  setup(props) {
    const color = computed(() => STATE_THEME[props.state?.polarity || StatePolarity.NEUTRAL].color)
    return {
      color
    }
  }
})
</script>

<style lang="stylus">
.sz-builder-state
  display inline-block
  margin 3px 3px
  &:last-child
    margin-right 0
</style>
