<template>
  <div class="sz-submodel-container">
    <div ref="submodelTableWrapper" class="sz-submodel-table-wrapper">
      <a-table
        v-if="data.length !== 0"
        :data-source="data"
        :columns="columns"
        :pagination="false"
        class="sz-submodel-table"
        size="small"
        :scroll="{ x: true, y: scrollY }"
      >
        <template #first="{ text: groupKey }">
          <a-tooltip placement="top">
            <template #title>
              <span>{{ groupKey }}</span>
            </template>
            <span class="sz-submodel-group-key">{{ groupKey }}</span>
          </a-tooltip>
        </template>
        <template #members="{ text: memberKeys }">
          <a-tag v-for="(memberKey, index) in memberKeys" :key="index" color="blue">
            {{ memberKey }}
          </a-tag>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  PropType,
  Ref,
  ref,
  toRef,
  watch
} from 'vue'

import { tableScrollY } from '@/libs/utils'
import { RowData } from '@/types'

import { BSubmodel } from './libs/common'

export default defineComponent({
  props: {
    bSubmodels: {
      type: Array as PropType<Array<BSubmodel>>,
      default: () => []
    }
  },
  setup(props) {
    const scrollY: Ref<null | number> = ref(null)
    const submodelTableWrapper = ref(null)
    const bSubmodels = toRef(props, 'bSubmodels')

    const columns = computed(() => {
      let cols: any = [
        {
          title: 'Sub Model',
          key: 'groupKey',
          dataIndex: 'groupKey',
          fixed: 'left',
          width: 150,
          slots: {
            customRender: 'first'
          }
        },
        {
          title: 'Members',
          key: 'memberKeys',
          dataIndex: 'memberKeys',
          slots: {
            customRender: 'members'
          }
        }
      ]
      cols = cols.concat([
        {
          title: '',
          key: 'filler'
        }
      ])
      return cols
    })

    const data = computed(() => {
      return bSubmodels.value?.map((bSubmodel) => {
        const rowData: RowData = {
          key: bSubmodel.groupKey,
          groupKey: bSubmodel.groupKey,
          memberKeys: bSubmodel.memberKeys
        }
        return rowData
      })
    })

    /**
     * Get table scroll y value
     **/
    const onResize = () => {
      const y = tableScrollY(submodelTableWrapper.value, 40)
      if (scrollY.value !== y) {
        scrollY.value = y
      }
    }

    onBeforeMount(() => {
      window.addEventListener('resize', onResize)
    })

    watch(data, () => {
      onResize()
    })

    onMounted(() => {
      onResize()
    })

    onUpdated(() => {
      onResize()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      columns,
      data,
      submodelTableWrapper,
      scrollY
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/vars.styl"
@import "../../styles/commons.styl"

.sz-submodel-container
  display: flex
  flex-direction: column
  min-height: 0
  overflow: hidden
  position: relative
  height 100%
  .sz-submodel-table-wrapper
    flex: 1 1 auto
    display: flex
    flex-direction: column
    min-height: 0
    overflow: hidden
    position: relative
    height 100%
    flex 1
    overflow hidden
    tr
      td:nth-child(1)
      td:nth-child(3)
      td:nth-child(4)
        background-color #f0f0f0

    td
      padding 0 1px 0 1px !important
      white-space: nowrap;

    th
      background-image: linear-gradient(90deg, silver 0%, rgba(202,202,202,1) 100%);

    // th:first-child
    //   border-right: none;
    //   border-left: 1px solid white;

    td, th
      border-bottom: 1px solid #ffffff;
    .sz-submodel-table
      flex: 1 1 auto
      .ant-table-tbody > tr > td
        padding: 0 0 0 1px !important

      .sz-submodel-group-key
        @extend .truncate
        display: inline-block
        width: 149px
        padding: 0 5px 0 8px
</style>
