<template>
  <div class="sz-static-matrix-container">
    <div class="sz-static-matrix-settings">
      <a-space>
        <a-input v-model:value="childFilter" size="small" placeholder="Child filter" />
        <a-input v-model:value="parentFilter" size="small" placeholder="Parent filter" />
        <a-button size="small" @click="applyFilter">Apply filter</a-button>
        <a-button size="small" @click="clearFilter">Clear filter</a-button>
      </a-space>
    </div>
    <div class="sz-static-matrix-headings">
      <div>Child</div>
      <div>Parents</div>
    </div>
    <div ref="matrixTableWrapper" class="sz-static-matrix-table-wrapper">
      <div v-for="(row, index) in adjacencyRows" :key="index" class="sz-static-matrix-row">
        <div>{{ row.from.name }}</div>
        <div>
          <a-tag v-for="(parent, j) in row.parents" :key="j" color="blue">
            {{ bNodeByKeyMap[parent]?.bNode?.name }}
          </a-tag>
          <!-- <span v-for="(parent, j) in row.parents" :key="j" class="sz-static-matrix-parent">
            {{ bNodeByKeyMap[parent]?.bNode?.name }}
          </span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { GenericGraphAdapter } from 'incremental-cycle-detect'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  PropType,
  reactive,
  Ref,
  ref,
  toRef,
  watch
} from 'vue'

import { tableScrollY } from '@/libs/utils'
import { RowData } from '@/types'

import { BEdge, BNode } from './libs/common'

export const EVENTS = {
  ON_TOGGLE_EDGE: 'onToggleEdge'
}

export default defineComponent({
  props: {
    graph: {
      type: Object as PropType<GenericGraphAdapter<any, any>>,
      default: () => ({})
    },
    bNodes: {
      type: Array as PropType<Array<BNode>>,
      default: () => []
    },
    bEdges: {
      type: Array as PropType<Array<BEdge>>,
      default: () => []
    },
    bNodeByKeyMap: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    },
    adjacencyListMap: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    },
    bEdgeByKeyMap: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const childFilter: Ref<string> = ref('')
    const parentFilter: Ref<string> = ref('')
    const scrollY: Ref<null | number> = ref(null)
    const matrixTableWrapper = ref(null)

    const childKeyword: Ref<string> = ref('')
    const parentKeyword: Ref<string> = ref('')

    // Initialise cycle detection
    const bNodes = toRef(props, 'bNodes')
    const bEdgeByKeyMap = toRef(props, 'bEdgeByKeyMap')
    const adjacencyListMap = toRef(props, 'adjacencyListMap')
    const linkMap: Record<string, boolean> = reactive({})

    const initEnableMap = (): Record<string, boolean> => {
      let map: Record<string, boolean> = {}
      bNodes?.value.map((nodeRow) => {
        bNodes?.value.map((nodeCol) => {
          const key = nodeRow.key + '-' + nodeCol.key
          map[key] = true
        })
      })
      return map
    }

    const enableMap: Ref<Record<string, boolean>> = ref(initEnableMap())

    // const updateLinkMap = () => {
    //   Object.keys(bEdgeByKeyMap.value).forEach((key) => {
    //     const bEdge: BEdge = bEdgeByKeyMap.value[key]
    //     linkMap[key] = true
    //     props.graph?.addEdge(bEdge.child, bEdge.parent)
    //   })
    // }

    const refreshEnableMap = () => {
      if (enableMap.value) {
        bNodes?.value.map((nodeRow) => {
          bNodes?.value.map((nodeCol) => {
            const key = nodeRow.key + '-' + nodeCol.key
            if (linkMap[key]) {
              enableMap.value[key] = true
            } else {
              enableMap.value[key] = props.graph?.canAddEdge(nodeRow.key, nodeCol.key)
            }
          })
        })
      }
    }

    watch(bEdgeByKeyMap, () => {
      // updateLinkMap()
      // refreshEnableMap()
    })

    onMounted(() => {
      // updateLinkMap()
      // refreshEnableMap()
    })

    const childNodes = computed(() => {
      return !childKeyword.value?.length
        ? bNodes.value
        : bNodes.value?.filter(({ key }) => {
            return key?.indexOf(childKeyword.value) !== -1
          }) || []
    })

    const parentNodes = computed(() => {
      return !parentKeyword.value?.length
        ? bNodes.value
        : bNodes.value?.filter(({ key }) => {
            return key?.indexOf(parentKeyword.value) !== -1
          }) || []
    })

    const columns = computed(() => {
      let cols: any = [
        {
          title: 'Child → Parents',
          key: 'child',
          dataIndex: 'from',
          fixed: 'left',
          width: 150,
          slots: {
            customRender: 'first'
          },
          customHeaderCell: () => {
            return {
              style: {
                height: '150px'
              },
              class: 'sz-matrix-corner'
            }
          }
        }
      ]
      cols = cols.concat(
        parentNodes.value?.map((bNode, index) => {
          return {
            title: bNode.key,
            key: index,
            dataIndex: bNode.key,
            width: 36,
            slots: {
              customRender: 'link'
            },
            customHeaderCell: () => {
              return {
                class: 'sz-parent-col'
              }
            }
          }
        })
      )
      cols = cols.concat([
        {
          title: '',
          key: 'filler'
        }
      ])
      return cols
    })

    const data = computed(() => {
      return childNodes.value?.map((nodeRow, row) => {
        const rowData: RowData = {
          from: nodeRow,
          key: nodeRow.key
        }
        parentNodes.value?.map((nodeCol, col) => {
          rowData[nodeCol.key] = {
            rowKey: nodeRow.key,
            colKey: nodeCol.key,
            row,
            col,
            key: `${nodeRow.key}-${nodeCol.key}`
          }
        })
        return rowData
      })
    })

    const adjacencyRows = computed(() => {
      return childNodes.value?.map((nodeRow, row) => {
        const childKey = nodeRow.key
        const parents = adjacencyListMap.value[childKey] || []
        const rowData: RowData = {
          row,
          from: nodeRow,
          key: childKey,
          parents
        }
        return rowData
      })
    })

    const onToggleLink = (key: string, rowKey: string, colKey: string) => {
      let change = null
      if (!linkMap[key]) {
        // Only add if pass cycle detection
        if (props.graph?.canAddEdge(rowKey, colKey)) {
          props.graph?.addEdge(rowKey, colKey)
          change = true
        }
      } else {
        props.graph?.deleteEdge(rowKey, colKey)
        change = false
      }
      if (change !== null) {
        linkMap[key] = change
        refreshEnableMap()
        emit(EVENTS.ON_TOGGLE_EDGE, {
          child: rowKey,
          parent: colKey,
          change
        })
      }
    }

    const applyFilter = () => {
      childKeyword.value = childFilter.value.trim()
      parentKeyword.value = parentFilter.value.trim()
    }

    const clearFilter = () => {
      childFilter.value = ''
      parentFilter.value = ''
      childKeyword.value = ''
      parentKeyword.value = ''
    }

    /**
     * Get table scroll y value
     **/
    const onResize = () => {
      const y = tableScrollY(matrixTableWrapper.value, 180)
      if (scrollY.value !== y) {
        scrollY.value = y
      }
    }

    onBeforeMount(() => {
      window.addEventListener('resize', onResize)
    })

    watch(data, () => {
      onResize()
    })

    onMounted(() => {
      onResize()
    })

    onUpdated(() => {
      onResize()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      adjacencyRows,
      applyFilter,
      clearFilter,
      columns,
      data,
      enableMap,
      linkMap,
      matrixTableWrapper,
      onToggleLink,
      scrollY,
      childFilter,
      parentFilter
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/vars.styl"
@import "../../styles/commons.styl"

.sz-static-matrix-container
  display: flex
  flex-direction: column
  min-height: 0
  overflow: hidden
  position: relative
  height 100%
  .sz-static-matrix-settings
    @extend .centered
    flex: 0 0 auto
    height: 40px
    padding-left: 10px
  .sz-static-matrix-headings
    @extend .centered
    flex: 0 0 auto
    height: 40px
    flex-direction: row
    > div
      background-image: linear-gradient(90deg, #c0c0c0 0%, #cacaca 100%)
      padding-top: 10px
    > div:first-child
      width: 100px
      padding-left: 10px
      flex 0 0 auto
    > div:nth-child(2)
      flex 1 1 auto
  .sz-static-matrix-table-wrapper
    flex: 1 1 auto
    display: flex
    flex-direction: column
    min-height: 0
    overflow: auto
    position: relative
    height 100%
    background-color: white
    .sz-static-matrix-row
      margin-top: 1px
      height: 28px
      display: flex
      flex-direction: row
      > div
        padding: 2px
      > div:first-child
        width: 100px
        padding-left: 10px
        flex 0 0 auto
        background-color: rgba(240,240,240, 0.85)
      > div:nth-child(2)
        flex 1 1 auto
        padding-left: 10px
        .sz-static-matrix-parent
          border: 1px solid #cccccc
          padding: 2px
          border-radius 3px
          margin-right: 4px
</style>
