import { GenericGraphAdapter } from 'incremental-cycle-detect'
import { indexBy, isEmpty, prop, repeat } from 'ramda'
import builder from 'xmlbuilder'

import { indexToPolarity } from '@/libs/bayes'
import { checkNodeType, NodeType, StatePolarity } from '@/libs/bayes/enums'
import { objectId } from '@/libs/utils'
import { EdgeSchema, NodeSchema, SubmodelSchema } from '@/types'

export type BState = {
  id?: string
  name: string
  polarity?: StatePolarity
}

export type BNode = {
  id?: string
  key: string
  name: string
  type: NodeType
  states: BState[]
}

export type BEdge = {
  id?: string
  child: string
  parent: string
  removed: boolean
}

export type BSubmodel = {
  id?: string
  name: string
  groupKey: string
  memberKeys: string[]
  serialized?: any
}

export const NODE_DEFAULT = {
  selectable: false,
  locked: true,
  grabbable: false,
  pannable: false
}

export const getBNodeMapByKey = (bNodes: BNode[]): any => {
  return bNodes.reduce((acc: Record<string, any>, bNode, index) => {
    if (bNode.key) {
      acc[bNode.key] = {
        bNode,
        index
      }
    }
    return acc
  }, {})
}

export const getBNodeMapById = (bNodes: BNode[]): any => {
  return bNodes.reduce((acc: Record<string, any>, bNode) => {
    if (bNode.id) {
      acc[bNode.id] = bNode
    }
    return acc
  }, {})
}

export const getBEdgeMapByKey = (bEdges: BEdge[]): any => {
  return bEdges.reduce((acc: Record<string, any>, bEdge, index) => {
    if (bEdge.child && bEdge.parent) {
      acc[bEdge.child + '-' + bEdge.parent] = {
        bEdge,
        index
      }
    }
    return acc
  }, {})
}

export const getAdjacencyListMap = (bEdges: BEdge[]): any => {
  return bEdges.reduce((acc: Record<string, any>, bEdge) => {
    if (bEdge.child && bEdge.parent) {
      if (!(bEdge.child in acc)) {
        acc[bEdge.child] = []
      }
      acc[bEdge.child].push(bEdge.parent)
    }
    return acc
  }, {})
}

export const rawToNodes = (rawValue: string): any => {
  const bNodes: BNode[] = []
  let annotatedRaw = ''
  let erroMessage = ''
  let annotation
  const keyMap: Record<string, any> = {}
  try {
    const rows = rawValue.split(/\r?\n/)
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const cols = row.split(/\s*,\s*/)
      const key = cols[0]
      const _type = cols[2] || 'CPT'
      const type: NodeType = checkNodeType(_type)
      if (!key) {
        if (row.length >= 1) {
          annotation = `#${row} // missing key\n`
          annotatedRaw += annotation
          erroMessage += annotation
        }
        continue
      }
      if (key in keyMap) {
        annotation = `#${row} // duplicated key\n`
        annotatedRaw += annotation
        erroMessage += annotation
        continue
      }
      if (type == NodeType.UNKNOWN) {
        annotation = `#${row} // unknown node type \n`
        annotatedRaw += annotation
        erroMessage += annotation
        continue
      }
      keyMap[key] = true
      annotatedRaw += `${row}\n`
      let name = cols[1]
      if (!name || !name?.length) {
        name = key
      }
      let stateNames = cols.slice(3)
      if (!stateNames?.length) {
        stateNames = ['state0', 'state1']
      }
      const states: BState[] = stateNames.map((stateName, index) => ({
        id: objectId(),
        name: stateName,
        polarity: indexToPolarity(index, stateNames.length)
      }))
      const node = {
        key,
        name,
        type,
        states
      }
      bNodes.push(node)
    }
  } catch (e) {
    // pass
  }
  return {
    parsedBNodes: bNodes,
    annotatedRaw,
    erroMessage
  }
}

export const normalizeNodes = (persistedNodes: NodeSchema[]): BNode[] =>
  persistedNodes.map(({ id, name, type, shortName, states }) => ({
    id,
    key: shortName || id,
    name,
    type,
    states: states.map(({ id, name }, index) => ({
      id,
      name,
      polarity: indexToPolarity(index, states.length)
    }))
  }))

const defaultNodeDefinition = (
  isDeterministic: boolean,
  stateNum: number,
  parentStatesNums: number[]
): number[] => {
  const parentNum = parentStatesNums.length
  let probs
  let definition
  if (isDeterministic) {
    probs = repeat(0, stateNum)
    probs[stateNum - 1] = 1.0
  } else {
    probs = repeat(1.0 / stateNum, stateNum)
  }
  if (parentNum === 0) {
    definition = probs
  } else {
    const combCount = parentStatesNums.reduce(
      (accumulator, parentStatesNum) => accumulator * parentStatesNum,
      1
    )
    definition = [].concat(...Array(combCount).fill(probs))
  }
  return definition
}

export const getParentsMap = (bEdges: BEdge[]): any => {
  const parentsMap: Record<string, any> = {}
  bEdges.forEach(({ child, parent }) => {
    if (!parentsMap[parent]) {
      parentsMap[parent] = []
    }
    parentsMap[parent].push(child)
  })
  return parentsMap
}

export const serializeNodes = (
  workingNodes: BNode[],
  workingEdges: BEdge[],
  nodeMapByKey: Record<string, any>
): NodeSchema[] => {
  const parentsMap = getParentsMap(workingEdges)
  return workingNodes.map(({ id, key, name, type, states }) => {
    const parentKeys: string[] = parentsMap[key] || []
    const parents = parentKeys.map((key) => nodeMapByKey[key])
    const nodeDefinition = defaultNodeDefinition(
      type === NodeType.TRUTH_TABLE,
      states.length || 2,
      parents.map((parent: BNode) => parent?.states?.length || 2)
    )
    return {
      id: id || objectId(),
      shortName: key,
      name,
      type,
      nodeDefinition,
      states: states.map(({ id, name }) => ({
        id: id || objectId(),
        name
      }))
    }
  })
}

/**
 * Modify the parent states
 * child is the already persisted list of states
 */
export const reconcileStates = (
  persistedStates: BState[] = [],
  workingStates: BState[] = []
): BState[] => {
  const result = []
  const persistedMap = indexBy(prop('name'), persistedStates)

  for (let i = 0; i < workingStates.length; i++) {
    const workingState = workingStates[i]
    const { name } = workingState
    if (name in persistedMap) {
      // Carry over the id from the child
      Object.assign(workingState, persistedMap[name])
    }
    result.push(workingState)
  }
  return result
}

/**
 * Modify the child nodes
 */
export const reconcileNodes = (
  persistedNodes: BNode[] = [],
  workingNodes: BNode[] = []
): BNode[] => {
  const result = [...persistedNodes]
  const resultMap = indexBy(prop('key'), result)

  for (let i = 0; i < workingNodes.length; i++) {
    const workingNode = workingNodes[i]
    const { key } = workingNode
    if (key in resultMap) {
      Object.assign(resultMap[key], workingNode)
      resultMap[key].states = reconcileStates(resultMap[key].states, workingNode.states)
    } else {
      result.push(workingNode)
    }
  }
  return result
}

export const rawToEdges = (
  graph: GenericGraphAdapter<any, any>,
  rawValue: string,
  nodeMapByKey: Record<string, any>
): any => {
  const bEdges: BEdge[] = []
  let annotatedRaw = ''
  try {
    const rows = rawValue.split(/\r?\n/)
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      if (row.startsWith('#')) {
        continue
      }
      const cols = row.split(/\s*,\s*/)
      const child = cols[0]
      const parents = cols.slice(1)
      if (!child || !parents || parents.length == 0) {
        annotatedRaw += `#${child},${parents} // missing child or parents \n`
        continue
      }
      for (let j = 0; j < parents.length; j++) {
        const parent = parents[j]
        if (!nodeMapByKey[child] || !nodeMapByKey[parent]) {
          annotatedRaw += `#${child},${parent} // missing nodes\n`
          break
        }
        if (graph.canAddEdge(child, parent)) {
          annotatedRaw += `${child},${parent}\n`
          graph.addEdge(child, parent)
        } else {
          annotatedRaw += `#${child},${parent} // cyclic dependency\n`
          break
        }
        const bEdge = {
          child,
          parent,
          removed: false
        }
        bEdges.push(bEdge)
      }
    }
  } catch (e) {
    // pass
  }
  return {
    parsedBEdges: bEdges,
    annotatedRaw
  }
}

export const normalizeEdges = (
  persistedEdges: EdgeSchema[],
  persistedNodes: NodeSchema[]
): BEdge[] => {
  const persistedNodeMap: Record<string, any> = indexBy(prop('id'), persistedNodes)
  const edges: BEdge[] = []
  persistedEdges.forEach(({ id, source, target }) => {
    if (persistedNodeMap[target] && persistedNodeMap[source]) {
      edges.push({
        id,
        child: persistedNodeMap[target].shortName,
        parent: persistedNodeMap[source].shortName,
        removed: false
      })
    }
  })
  return edges
}

export const denormalizeEdges = (
  workingEdges: BEdge[],
  serializedNodes: NodeSchema[]
): EdgeSchema[] => {
  const serializedNodeMap: Record<string, any> = indexBy(prop('shortName'), serializedNodes)
  const persistedEdges: EdgeSchema[] = []
  workingEdges.forEach(({ id, child: childKey, parent: parentKey, removed }) => {
    const child = serializedNodeMap[childKey].id
    const parent = serializedNodeMap[parentKey].id
    if (child && parent && !removed) {
      persistedEdges.push({
        id: id || objectId(),
        target: child,
        source: parent
      })
    }
  })
  return persistedEdges
}

export const reconcileEdges = (
  persistedEdges: BEdge[] = [],
  workingEdges: BEdge[] = []
): BEdge[] => {
  const result: BEdge[] = []
  const persistedMap = indexBy(({ child, parent }) => child + '->' + parent, persistedEdges)

  for (let i = 0; i < workingEdges.length; i++) {
    const workingEdge = workingEdges[i]
    const { child, parent } = workingEdge
    const edgeKey = child + '->' + parent
    if (edgeKey in persistedMap) {
      // Carry over the id from the persisted
      Object.assign(workingEdge, persistedMap[edgeKey])
    } else {
      result.push(workingEdge)
    }
  }
  return result
}

export const serializeEdges = (
  workingEdges: BEdge[],
  serializedNodes: NodeSchema[]
): EdgeSchema[] => {
  const serializedNodeMap: Record<string, any> = indexBy(prop('shortName'), serializedNodes)
  return workingEdges.map(({ id, child, parent }) => ({
    id: id || objectId(),
    target: serializedNodeMap[child]?.id,
    source: serializedNodeMap[parent]?.id
  }))
}

export const getBSubmodelMapByKey = (bSubmodels: BSubmodel[]): any => {
  return indexBy(prop('groupKey'), bSubmodels)
}

export const rawToSubmodels = (rawValue: string, nodeMapByKey: Record<string, any>): any => {
  const parsedBSubmodels: BSubmodel[] = []
  const subModelKey: Record<string, any> = {}
  let annotatedRaw = ''
  let errorMessage = ''
  let annotation
  try {
    const rows = rawValue.split(/\r?\n/)
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      if (row.startsWith('#')) {
        annotatedRaw = `${row}\n`
        continue
      }
      if (row.length === 0 || row.trim().length === 0) {
        annotation = `# // empty line\n`
        annotatedRaw += annotation
        errorMessage += annotation
        continue
      }
      const cols = row.split(/\s*,\s*/)
      const groupKey = cols[0]
      if (isEmpty(groupKey)) {
        annotation = `#${row} // empty sub model key\n`
        annotatedRaw += annotation
        errorMessage += annotation
        continue
      }
      const name = cols[1]
      const members = cols.slice(2)
      const memberKeys = []
      let error = false
      for (let j = 0; j < members.length; j++) {
        const memberKey = members[j]
        if (isEmpty(memberKey)) {
          annotation = `#${row} // empty member key\n`
          annotatedRaw += annotation
          errorMessage += annotation
          error = true
          break
        }
        if (!nodeMapByKey[memberKey] && !subModelKey[memberKey]) {
          annotation = `#${row} // ${memberKey} should be first defined as node or submodel\n`
          annotatedRaw += annotation
          errorMessage += annotation
          error = true
          break
        }
        memberKeys.push(memberKey)
      }
      if (error) {
        continue
      }
      const bSubmodel: BSubmodel = {
        name,
        groupKey,
        memberKeys
      }
      subModelKey[groupKey] = bSubmodel
      parsedBSubmodels.push(bSubmodel)
      annotatedRaw += `${row}\n`
    }
  } catch (e) {
    console.log(e)
    // pass
  }
  let hasDuplicate = false
  for (let i = 1; i < parsedBSubmodels.length; i++) {
    const currentMembers = parsedBSubmodels[i].memberKeys
    for (let j = 0; j < i; j++) {
      const prevMembers = parsedBSubmodels[j].memberKeys
      const newMembers = []
      for (let m = 0; m < prevMembers.length; m++) {
        const prevMember = prevMembers[m]
        if (!currentMembers.includes(prevMember)) {
          newMembers.push(prevMember)
        } else {
          if (!hasDuplicate) {
            hasDuplicate = true
            annotation = `# Duplicated children are found, only the last is taken\n`
            annotatedRaw += annotation
            errorMessage += annotation
          }
        }
      }
      parsedBSubmodels[j].memberKeys = newMembers
    }
  }
  return {
    annotatedRaw,
    parsedBSubmodels,
    errorMessage
  }
}

export const normalizeSubmodels = (
  persistedSubmodels: SubmodelSchema[],
  persistedNodes: NodeSchema[]
): BSubmodel[] => {
  const subModels: BSubmodel[] = []
  const persistedNodeMap: Record<string, any> = indexBy(prop('id'), persistedNodes)
  const processSubModels = (
    subModels: SubmodelSchema[],
    flatModels: BSubmodel[],
    breaker: number
  ) => {
    subModels.forEach(({ id, shortName, name, subModels: subSubModels, variableIds }) => {
      let memberKeys: string[] = []
      if (subSubModels && subSubModels.length && breaker) {
        memberKeys = subSubModels.map((subSubModel) => subSubModel.shortName)
        processSubModels(subSubModels, flatModels, breaker - 1)
      }
      if (variableIds) {
        memberKeys = memberKeys.concat(
          variableIds.map((variableId: string) => persistedNodeMap[variableId]?.shortName)
        )
      }
      flatModels.push({
        id,
        name,
        groupKey: shortName,
        memberKeys
      })
    })
  }
  processSubModels(persistedSubmodels, subModels, 5)
  return subModels
}

export const serializeSubmodels = (
  workingSubmodels: BSubmodel[],
  serializedNodes: NodeSchema[]
): any => {
  const serializedNodeMap: Record<string, any> = indexBy(prop('shortName'), serializedNodes)
  const submodelMap: Record<string, any> = indexBy(prop('groupKey'), workingSubmodels)
  const roots = new Set()
  const children = new Set()
  const memberNodeKeys = new Set()

  for (let i = 0; i < workingSubmodels.length; i++) {
    const submodel = workingSubmodels[i]
    if (!submodel.id) {
      submodel.id = objectId()
    }
    const { groupKey, memberKeys } = submodel
    // groupKey is parent
    for (let j = 0; j < memberKeys.length; j++) {
      const memberKey = memberKeys[j]
      roots.delete(memberKey)
      children.add(memberKey)
      if (!children.has(groupKey)) {
        roots.add(groupKey)
      }
    }
  }

  const buildSubmodelNode = (key: string) => {
    if (key in submodelMap) {
      const { id, groupKey, name, memberKeys } = submodelMap[key]
      const subModels: any[] = []
      const variableIds: any[] = []
      memberKeys.forEach((key: string) => {
        if (key in serializedNodeMap) {
          variableIds.push(serializedNodeMap[key].id)
          memberNodeKeys.add(key)
        } else if (key in submodelMap) {
          subModels.push(buildSubmodelNode(key))
        }
      })
      return {
        id,
        shortName: groupKey,
        name,
        subModels,
        variableIds
      }
    }
  }
  const serialized: any[] = []
  roots.forEach((rootKey) => {
    serialized.push(buildSubmodelNode(rootKey as string))
  })
  console.log('serialized', serialized, JSON.stringify(serialized, null, 2))
  return {
    roots,
    serialized,
    memberNodeKeys
  }
}

export const serializeSubmodelsToEls = (
  workingSubmodels: BSubmodel[],
  workingNodes: BNode[],
  genie: builder.XMLElement
): any => {
  const nodeKeyMap: Record<string, any> = indexBy(prop('key'), workingNodes)
  const submodelMap: Record<string, any> = indexBy(prop('groupKey'), workingSubmodels)
  const roots = new Set()
  const children = new Set()
  const memberNodeKeys = new Set()

  for (let i = 0; i < workingSubmodels.length; i++) {
    const submodel = workingSubmodels[i]
    const { groupKey, memberKeys } = submodel
    // groupKey is parent
    for (let j = 0; j < memberKeys.length; j++) {
      const memberKey = memberKeys[j]
      roots.delete(memberKey)
      children.add(memberKey)
      if (!children.has(groupKey)) {
        roots.add(groupKey)
      }
    }
  }

  const createEl = (parent: builder.XMLElement, type: string, key: string, name: string) => {
    const el = parent.ele(type)
    el.att('id', key)
    el.ele('name').txt(name)
    el.ele('position').txt('0 800 100 850')
    el.ele('interior').att('color', 'e5f6f7')
    el.ele('outline').att('color', '0000bb').att('width', '3')
    el.ele('font').att('color', '000000').att('name', 'Arial').att('size', '8')
    return el
  }

  const buildSubmodelEl = (parent: builder.XMLElement, key: string) => {
    if (key in submodelMap) {
      const { groupKey, name, memberKeys } = submodelMap[key]
      const el = createEl(parent, 'submodel', groupKey, name)
      memberKeys.forEach((key: string) => {
        if (key in nodeKeyMap) {
          createEl(el, 'node', key, nodeKeyMap[key].name)
        } else if (key in submodelMap) {
          buildSubmodelEl(el, key)
        }
      })
      return el
    }
  }
  const serialized: any[] = []
  roots.forEach((rootKey) => {
    serialized.push(buildSubmodelEl(genie, rootKey as string))
  })
  return {
    roots,
    serialized,
    memberNodeKeys
  }
}

// Deprecated
export const serializeToXdsl = (
  workingNodes: BNode[] = [],
  workingEdges: BEdge[] = [],
  workingSubmodels: BSubmodel[]
): any => {
  const parentsMap = getParentsMap(workingEdges)
  const nodeKeyMap: Record<string, any> = indexBy(prop('key'), workingNodes)

  const root = builder.create('smile')
  const nodes = root.ele('nodes')
  root.att('version', '1.0')
  root.att('id', 'builder')
  workingNodes.forEach((bNode: BNode) => {
    const { key, states, type } = bNode
    const node = nodes.ele(type.toLowerCase())
    node.att('id', key)
    states.forEach(({ name }) => {
      const state = node.ele('state')
      state.att('id', name)
    })
    if (parentsMap[key]) {
      const parents = node.ele('parents')
      parents.txt(parentsMap[key].join(' '))
    }
    if (type.toLowerCase() === 'cpt') {
      const parentsCombCount = (parentsMap[key] || []).reduce((acc: number, parentKey: string) => {
        return acc * nodeKeyMap[parentKey]?.states?.length || 1
      }, 1)
      const mult = states.length * parentsCombCount
      node.ele('probabilities').txt(repeat((1 / states.length).toString(), mult).join(' '))
    } else if (type.toLowerCase() === 'deterministic') {
      node.ele('resultingstates').txt(states[0].name)
    }
  })
  const extensions = root.ele('extensions')
  const genie = extensions.ele('genie')
  genie.att('version', '1.0')
  genie.att('name', 'builder')
  const { memberNodeKeys } = serializeSubmodelsToEls(workingSubmodels, workingNodes, genie)
  workingNodes.forEach((bNode: BNode) => {
    const { name, key } = bNode
    // already created
    if (memberNodeKeys.has(key)) {
      return
    }
    if (!name || !name.length) {
      return
    }
    const node = genie.ele('node')
    node.att('id', key)
    node.ele('name').txt(name)
    node.ele('position').txt('0 800 100 850')
    node.ele('interior').att('color', 'e5f6f7')
    node.ele('outline').att('color', '0000bb').att('width', '3')
    node.ele('font').att('color', '000000').att('name', 'Arial').att('size', '8')
  })
  return root.end({ pretty: true })
}
