<template>
  <Loading :is-show="isLoading" />
  <div class="sz-builder-page sz-wrapper">
    <div class="sz-command-bar">
      <a-button v-if="activeKey === '1'" type="link" @click="parseRawNodes">
        <template #icon><DeliveredProcedureOutlined /></template>
        Convert raw nodes
      </a-button>
      <a-button v-if="activeKey === '1'" type="link" @click="mergeRawNodes">
        <template #icon><DeliveredProcedureOutlined /></template>
        Merge raw nodes
      </a-button>
      <a-button v-if="activeKey === '2'" type="link" @click="parseRawEdges">
        <template #icon><DeliveredProcedureOutlined /></template>
        Convert raw edges
      </a-button>
      <a-button v-if="activeKey === '2'" type="link" @click="mergeRawEdges">
        <template #icon><DeliveredProcedureOutlined /></template>
        Merge raw edges
      </a-button>
      <a-button v-if="activeKey === '3'" type="link" @click="parseRawSubmodels">
        <template #icon><DeliveredProcedureOutlined /></template>
        Convert raw submodels
      </a-button>
      <a-button type="link" @click="doSaveNetwork">
        <template #icon><DeliveredProcedureOutlined /></template>
        Save network
      </a-button>
      <a-button v-if="false" type="link" @click="doExportToXdsl">
        <template #icon><DeliveredProcedureOutlined /></template>
        Export to XDSL
      </a-button>
      <a-button type="link" @click="doExportToXdslGenie">
        <template #icon><DeliveredProcedureOutlined /></template>
        Export to XDSL
      </a-button>
    </div>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="Nodes">
        <splitpanes class="default-theme">
          <pane min-size="10" size="60">
            <node-table
              :b-nodes="bNodes"
              class="sz-nodes-container"
              @[ON_REMOVE_NODE]="onRemoveNode"
            />
          </pane>
          <pane>
            <div class="sz-nodes-raw">
              <textarea
                ref="nodesTextAreaWrapper"
                :value="nodesRawValue"
                placeholder="Paste your raw nodes here"
                name="sz-nodes-textarea"
                spellcheck="false"
                @change="onNodesRawChange"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="Edges">
        <edge-table :nodes="nodes" />
      </a-tab-pane> -->
      <a-tab-pane key="2" tab="Links">
        <splitpanes class="default-theme">
          <pane min-size="10" size="80">
            <div class="sz-edges-container">
              <!-- <matrix
                :graph="graph"
                :b-nodes="bNodes"
                :b-edges="bEdges"
                :b-edge-by-key-map="bEdgeByKeyMap"
                @[ON_TOGGLE_EDGE]="onToggleEdge"
              /> -->
              <static-matrix
                :graph="graph"
                :b-nodes="bNodes"
                :b-edges="bEdges"
                :adjacency-list-map="adjacencyListMap"
                :b-node-by-key-map="bNodeByKeyMap"
                :b-edge-by-key-map="bEdgeByKeyMap"
              />
            </div>
          </pane>
          <pane>
            <div class="sz-edges-raw">
              <textarea
                ref="edgesTextAreaWrapper"
                :value="edgesRawValue"
                placeholder="Paste your raw edges here"
                name="sz-edges-textarea"
                spellcheck="false"
                @change="onEdgesRawChange"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Sub Models">
        <splitpanes class="default-theme">
          <pane min-size="10" size="50">
            <div class="sz-submodels-container">
              <submodel-table :b-submodels="bSubmodels" />
            </div>
          </pane>
          <pane>
            <div class="sz-edges-raw">
              <textarea
                ref="submodelsTextAreaWrapper"
                :value="submodelsRawValue"
                placeholder="Paste your raw submodels here"
                name="sz-submodels-textarea"
                spellcheck="false"
                @change="onSubmodelsRawChange"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script lang="ts">
import { DeliveredProcedureOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import { saveAs } from 'file-saver'
import { GenericGraphAdapter } from 'incremental-cycle-detect'
import localforage from 'localforage'
import { isEmpty } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'

import Loading from '@/components/loading/index.vue'
import { ModuleNames } from '@/constants/vuex'
import { exportNetworkAsXdsl } from '@/services/api/network'
import { saveNetwork } from '@/services/composition/builder'
import { useStore } from '@/store'
import { NetworkActionEnum } from '@/store/enums/actions'
import { NetworkStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'
import { WorkspaceId } from '@/types/vue'

import {
  BEdge,
  BNode,
  BSubmodel,
  getAdjacencyListMap,
  getBEdgeMapByKey,
  getBNodeMapByKey,
  normalizeEdges,
  normalizeNodes,
  normalizeSubmodels,
  rawToEdges,
  rawToNodes,
  rawToSubmodels,
  reconcileEdges,
  reconcileNodes,
  serializeEdges,
  serializeNodes,
  serializeSubmodels,
  serializeToXdsl
} from './libs/common'
// import EdgeTable from './EdgeTable.vue'
// import Matrix, { EVENTS as MATRIX_EVENTS } from './Matrix.vue'
import NodeTable, { EVENTS as NODE_EVENTS } from './NodeTable.vue'
import StaticMatrix from './StaticMatrix.vue'
import SubmodelTable from './SubmodelTable.vue'

// const { ON_TOGGLE_EDGE } = MATRIX_EVENTS
const { ON_REMOVE_NODE } = NODE_EVENTS
const { NETWORK } = ModuleNames
const LSTORAGE_KEY = {
  NODES_RAW: 'NODES_RAW',
  EDGES_RAW: 'EDGES_RAW',
  SUBMODELS_RAW: 'SUBMODELS_RAW'
}

export default defineComponent({
  components: {
    // EdgeTable,
    Pane,
    Splitpanes,
    DeliveredProcedureOutlined,
    // Matrix,
    Loading,
    StaticMatrix,
    NodeTable,
    SubmodelTable
  },
  props: {
    workspaceId: {
      type: String as WorkspaceId,
      required: true
    }
  },
  setup(props) {
    const builderLocalStore = localforage.createInstance({
      name: `builder-${props.workspaceId}`
    })
    const store = useStore()
    const activeKey = ref('1')
    const isLoading = ref(false)
    const nodesRawValue = ref('')
    const nodesTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const edgesRawValue = ref('')
    const edgesTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const submodelsRawValue = ref('')
    const submodelsTextAreaWrapper: Ref<HTMLElement | null> = ref(null)

    const currentNetwork = computed(() => store.state[NETWORK][NetworkStateEnum.CURRENT_NETWORK])
    const persistedNodes = computed(() => currentNetwork.value?.nodes || [])
    const persistedEdges = computed(() => currentNetwork.value?.edges || [])
    const persistedSubmodels = computed(() => currentNetwork.value?.subModels || [])
    const bNodes: Ref<Array<BNode>> = ref([])
    const bEdges: Ref<Array<BEdge>> = ref([])
    const bSubmodels: Ref<Array<BSubmodel>> = ref([])
    const bNodeByKeyMap: Ref<Record<string, any>> = ref({})
    const bEdgeByKeyMap: Ref<Record<string, any>> = ref({})
    const adjacencyListMap: Ref<Record<string, any>> = ref({})

    let graph = GenericGraphAdapter.create()

    const getNodesRaw = () => (nodesTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''
    const getEdgesRaw = () => (edgesTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''
    const getSubmodelsRaw = () =>
      (submodelsTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const parseRawNodes = () => {
      isLoading.value = true
      setTimeout(() => {
        nodesRawValue.value = getNodesRaw()
        const { parsedBNodes, annotatedRaw } = rawToNodes(nodesRawValue.value)
        bNodes.value = parsedBNodes
        nodesRawValue.value = annotatedRaw
        bNodeByKeyMap.value = getBNodeMapByKey(bNodes.value)
        isLoading.value = false
        message.success('Raw nodes are successfully parsed')
      }, 0)
    }

    const mergeRawNodes = () => {
      isLoading.value = true
      setTimeout(() => {
        nodesRawValue.value = getNodesRaw()
        const { parsedBNodes, annotatedRaw } = rawToNodes(nodesRawValue.value)
        bNodes.value = reconcileNodes(bNodes.value, parsedBNodes)
        nodesRawValue.value = annotatedRaw
        bNodeByKeyMap.value = getBNodeMapByKey(bNodes.value)
        isLoading.value = false
        message.success('Raw nodes are successfully merged')
      }, 0)
    }

    const parseRawEdges = () => {
      isLoading.value = true
      setTimeout(() => {
        edgesRawValue.value = getEdgesRaw()
        graph = GenericGraphAdapter.create()
        const { parsedBEdges, annotatedRaw } = rawToEdges(
          graph,
          edgesRawValue.value,
          bNodeByKeyMap.value
        )
        bEdges.value = parsedBEdges
        edgesRawValue.value = annotatedRaw
        bEdgeByKeyMap.value = getBEdgeMapByKey(bEdges.value)
        adjacencyListMap.value = getAdjacencyListMap(bEdges.value)
        isLoading.value = false
        message.success('Raw edges are successfully parsed')
      }, 0)
    }

    const mergeRawEdges = () => {
      isLoading.value = true
      setTimeout(() => {
        edgesRawValue.value = getEdgesRaw()
        const { parsedBEdges, annotatedRaw } = rawToEdges(
          graph,
          edgesRawValue.value,
          bNodeByKeyMap.value
        )
        bEdges.value = reconcileEdges(bEdges.value, parsedBEdges)
        edgesRawValue.value = annotatedRaw
        bEdgeByKeyMap.value = getBEdgeMapByKey(bEdges.value)
        adjacencyListMap.value = getAdjacencyListMap(bEdges.value)
        isLoading.value = false
        message.success('Raw edges are successfully merged')
      }, 0)
    }

    const parseRawSubmodels = () => {
      isLoading.value = true
      setTimeout(() => {
        submodelsRawValue.value =
          (submodelsTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''
        const { parsedBSubmodels, annotatedRaw, errorMessage } = rawToSubmodels(
          submodelsRawValue.value,
          bNodeByKeyMap.value
        )
        submodelsRawValue.value = annotatedRaw
        bSubmodels.value = parsedBSubmodels
        isLoading.value = false
        if (errorMessage) {
          Modal.error({
            title: () => 'Submodels parse issues',
            content: () => `${errorMessage}`
          })
        } else {
          message.success('Raw edges are successfully merged')
        }
      }, 0)
    }

    const parsePersistedNodes = () => {
      if (persistedNodes.value) {
        isLoading.value = true
        bNodes.value = normalizeNodes(persistedNodes.value)
        bNodeByKeyMap.value = getBNodeMapByKey(bNodes.value)
        isLoading.value = false
      }
    }

    const parsePersistedEdges = () => {
      if (persistedEdges.value) {
        isLoading.value = true
        bEdges.value = normalizeEdges(persistedEdges.value, persistedNodes.value)
        bEdgeByKeyMap.value = getBEdgeMapByKey(bEdges.value)
        adjacencyListMap.value = getAdjacencyListMap(bEdges.value)
        isLoading.value = false
      }
    }

    const parsePersistedSubmodels = () => {
      if (!isEmpty(persistedSubmodels.value) && !isEmpty(persistedNodes.value)) {
        bSubmodels.value = normalizeSubmodels(persistedSubmodels.value, persistedNodes.value)
      }
    }

    const onRemoveNode = ({ key }: any) => {
      if (key in bNodeByKeyMap.value) {
        const { index } = bNodeByKeyMap.value[key]
        if (bNodes.value[index]) {
          bNodes.value.splice(index, 1)
          bNodeByKeyMap.value = getBNodeMapByKey(bNodes.value)
          const newBEdges: BEdge[] = []
          bEdges.value.forEach((bEdge) => {
            const { child, parent } = bEdge
            if (child in bNodeByKeyMap.value && parent in bNodeByKeyMap.value) {
              newBEdges.push(bEdge)
            }
          })
          bEdges.value = newBEdges
          bEdgeByKeyMap.value = getBEdgeMapByKey(bEdges.value)
        }
      }
    }

    const onToggleEdge = ({ child, parent, change }: any) => {
      const key = child + '-' + parent
      if (key in bEdgeByKeyMap.value) {
        const { index } = bEdgeByKeyMap.value[key]
        if (bEdges.value[index]) {
          bEdges.value[index].removed = change
        }
      } else {
        if (change) {
          const bEdge = {
            parent,
            child,
            removed: false
          }
          bEdges.value.push(bEdge)
          bEdgeByKeyMap.value[key] = {
            bEdge,
            index: bEdges.value.length - 1
          }
        }
      }
    }

    const init = () => {
      message.info('Processing network ...')
      parsePersistedNodes()
      parsePersistedEdges()
      parsePersistedSubmodels()
      message.success('Network is successfully processed')
    }

    watch(currentNetwork, () => {
      init()
    })

    onMounted(async () => {
      init()
      nodesRawValue.value = (await builderLocalStore.getItem(LSTORAGE_KEY.NODES_RAW)) || ''
      edgesRawValue.value = (await builderLocalStore.getItem(LSTORAGE_KEY.EDGES_RAW)) || ''
      submodelsRawValue.value = (await builderLocalStore.getItem(LSTORAGE_KEY.SUBMODELS_RAW)) || ''
    })

    const onNodesRawChange = () => {
      builderLocalStore.setItem(LSTORAGE_KEY.NODES_RAW, getNodesRaw())
    }

    const onEdgesRawChange = () => {
      builderLocalStore.setItem(LSTORAGE_KEY.EDGES_RAW, getEdgesRaw())
    }

    const onSubmodelsRawChange = () => {
      builderLocalStore.setItem(LSTORAGE_KEY.SUBMODELS_RAW, getSubmodelsRaw())
    }

    const doSaveNetwork = async () => {
      if (currentNetwork.value) {
        bNodeByKeyMap.value = getBNodeMapByKey(bNodes.value)
        const persistedNodes = serializeNodes(bNodes.value, bEdges.value, bNodeByKeyMap.value)
        currentNetwork.value.nodes = persistedNodes
        currentNetwork.value.edges = serializeEdges(bEdges.value, currentNetwork.value.nodes)
        currentNetwork.value.subModels = serializeSubmodels(
          bSubmodels.value,
          persistedNodes
        ).serialized
        await saveNetwork(currentNetwork.value)
        // refresh the CURRENT NETWORK
        await store.dispatch(
          vuexActions(NETWORK, NetworkActionEnum.GET_NETWORK),
          currentNetwork.value?.id
        )
        message.success('Network is successfully saved')
      }
    }

    const doExportToXdsl = () => {
      const xdsl = serializeToXdsl(bNodes.value, bEdges.value, bSubmodels.value)
      saveAs(new Blob([xdsl], { type: 'application/xml;charset=utf-8' }), 'cpt-build.xdsl')
    }

    const doExportToXdslGenie = async () => {
      if (currentNetwork.value?.id) {
        const xdsl = await exportNetworkAsXdsl({ id: currentNetwork.value?.id })
        saveAs(new Blob([xdsl], { type: 'application/xml;charset=utf-8' }), 'cpt-build.xdsl')
      }
    }

    return {
      bEdges,
      bNodes,
      bSubmodels,
      adjacencyListMap,
      bNodeByKeyMap,
      bEdgeByKeyMap,
      edgesRawValue,
      nodesRawValue,
      submodelsRawValue,
      persistedEdges,
      persistedNodes,
      currentNetwork,
      parsePersistedEdges,
      activeKey,
      doSaveNetwork,
      edgesTextAreaWrapper,
      nodesTextAreaWrapper,
      submodelsTextAreaWrapper,
      parseRawEdges,
      mergeRawEdges,
      parseRawSubmodels,
      parseRawNodes,
      doExportToXdsl,
      doExportToXdslGenie,
      mergeRawNodes,
      ON_REMOVE_NODE,
      // ON_TOGGLE_EDGE,
      onToggleEdge,
      onRemoveNode,
      isLoading,
      graph,
      onSubmodelsRawChange,
      onNodesRawChange,
      onEdgesRawChange
    }
  }
})
</script>

<style lang="stylus">
@import '../../styles/commons.styl';

.sz-builder-page
  .ant-tabs
    flex: 1 1 auto
    display: flex
    flex-direction: column
  .ant-tabs-bar
    margin-bottom 0
  .ant-tabs-content
    flex: 1 1 auto

.sz-nodes-raw,
.sz-edges-raw
  padding: 10px
  background-color: #f7f7f7

.sz-nodes-container,
.sz-edges-container,
.sz-submodels-container
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

.sz-edges-raw,
.sz-nodes-raw
  @extend .fullcover
  textarea
    @extend .cover
    background-color: #f7f7f7
    padding: 10px
    border: none
    outline-width: 0px !important
    resize: none
</style>
