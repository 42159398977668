import { render } from "./BuilderPage.vue?vue&type=template&id=eab15154"
import script from "./BuilderPage.vue?vue&type=script&lang=ts"
export * from "./BuilderPage.vue?vue&type=script&lang=ts"

import "./BuilderPage.vue?vue&type=style&index=0&id=eab15154&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "eab15154"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('eab15154', script)) {
    api.reload('eab15154', script)
  }
  
  module.hot.accept("./BuilderPage.vue?vue&type=template&id=eab15154", () => {
    api.rerender('eab15154', render)
  })

}

script.__file = "src/components/builder/BuilderPage.vue"

export default script