import { render } from "./StaticMatrix.vue?vue&type=template&id=5d3f0073"
import script from "./StaticMatrix.vue?vue&type=script&lang=ts"
export * from "./StaticMatrix.vue?vue&type=script&lang=ts"

import "./StaticMatrix.vue?vue&type=style&index=0&id=5d3f0073&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5d3f0073"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5d3f0073', script)) {
    api.reload('5d3f0073', script)
  }
  
  module.hot.accept("./StaticMatrix.vue?vue&type=template&id=5d3f0073", () => {
    api.rerender('5d3f0073', render)
  })

}

script.__file = "src/components/builder/StaticMatrix.vue"

export default script