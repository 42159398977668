
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  PropType,
  Ref,
  ref,
  toRef,
  watch
} from 'vue'

import { tableScrollY } from '@/libs/utils'
import { RowData } from '@/types'

import { BSubmodel } from './libs/common'

export default defineComponent({
  props: {
    bSubmodels: {
      type: Array as PropType<Array<BSubmodel>>,
      default: () => []
    }
  },
  setup(props) {
    const scrollY: Ref<null | number> = ref(null)
    const submodelTableWrapper = ref(null)
    const bSubmodels = toRef(props, 'bSubmodels')

    const columns = computed(() => {
      let cols: any = [
        {
          title: 'Sub Model',
          key: 'groupKey',
          dataIndex: 'groupKey',
          fixed: 'left',
          width: 150,
          slots: {
            customRender: 'first'
          }
        },
        {
          title: 'Members',
          key: 'memberKeys',
          dataIndex: 'memberKeys',
          slots: {
            customRender: 'members'
          }
        }
      ]
      cols = cols.concat([
        {
          title: '',
          key: 'filler'
        }
      ])
      return cols
    })

    const data = computed(() => {
      return bSubmodels.value?.map((bSubmodel) => {
        const rowData: RowData = {
          key: bSubmodel.groupKey,
          groupKey: bSubmodel.groupKey,
          memberKeys: bSubmodel.memberKeys
        }
        return rowData
      })
    })

    /**
     * Get table scroll y value
     **/
    const onResize = () => {
      const y = tableScrollY(submodelTableWrapper.value, 40)
      if (scrollY.value !== y) {
        scrollY.value = y
      }
    }

    onBeforeMount(() => {
      window.addEventListener('resize', onResize)
    })

    watch(data, () => {
      onResize()
    })

    onMounted(() => {
      onResize()
    })

    onUpdated(() => {
      onResize()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      columns,
      data,
      submodelTableWrapper,
      scrollY
    }
  }
})
