<template>
  <builder :workspace-id="workspaceId" />
</template>

<script lang="ts">
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, useRoute } from 'vue-router'

import Builder from '@/components/builder/BuilderPage.vue'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { isInvalidRouterParamId, normalizeRouterParamId } from '@/libs/utils'
import { useStore } from '@/store'
import { NetworkActionEnum, WorkspaceActionEnum } from '@/store/enums/actions'
import { NetworkStateEnum, WorkspaceStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'

const { NETWORK, WORKSPACE } = ModuleNames

const loadPage: NavigationGuard = async (to, from, next) => {
  let { workspaceId } = to.params
  let errorMessage
  if (isInvalidRouterParamId(workspaceId)) {
    errorMessage = MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId)
  }
  if (errorMessage) {
    message.error({
      content: errorMessage,
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
    return
  }
  workspaceId = workspaceId as string
  const store = useStore()
  await store.dispatch(vuexActions(WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE), workspaceId)
  await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
  const currentNetwork = store.state[NETWORK][NetworkStateEnum.CURRENT_NETWORK]
  const currentNetworkId = currentNetwork?.id
  if (!currentNetworkId) {
    // blank network
    await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.CREATE_NETWORK), {
      workspaceId,
      network: {
        nodes: [],
        edges: [],
        workspaceId
      }
    })
    await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
  }

  next()
}

export default defineComponent({
  components: {
    Builder
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  setup() {
    const store = useStore()
    const route = useRoute()
    const workspaceId = computed(() => normalizeRouterParamId(route.params.workspaceId))

    const isGettingWorkspace = computed(() => store.state[WORKSPACE][WorkspaceStateEnum.IS_GETTING])
    const isGettingNetwork = computed(() => store.state[NETWORK][NetworkStateEnum.IS_GETTING])
    const isCreatingNetwork = computed(() => store.state[NETWORK][NetworkStateEnum.IS_CREATING])

    const isLoading = computed(
      () => isGettingWorkspace.value || isGettingNetwork.value || isCreatingNetwork.value
    )
    return {
      workspaceId,
      isLoading
    }
  }
})
</script>
