import { render } from "./BuilderView.vue?vue&type=template&id=c4abfed4"
import script from "./BuilderView.vue?vue&type=script&lang=ts"
export * from "./BuilderView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c4abfed4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c4abfed4', script)) {
    api.reload('c4abfed4', script)
  }
  
  module.hot.accept("./BuilderView.vue?vue&type=template&id=c4abfed4", () => {
    api.rerender('c4abfed4', render)
  })

}

script.__file = "src/views/BuilderView.vue"

export default script